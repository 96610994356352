import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../../../hoc/withAuthentication"
import { withMember } from "../../../hoc/withMember"
import { MembershipCancelled as Page } from "../../../components/Customer/Membership/MembershipCancelled"

export const query = graphql`
  query {
    page: sanityPageAccountMembershipCancelled {
      title
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default withAuthentication(withMember(({ data, ...props }) => <Page {...props} {...data} />))
