import React from "react"
import { navigate } from "gatsby"

import { useApp } from "../hooks/useApp"

export const withMember = Component => ({ ...props }) => {
  const {
    ready,
    member,
    config: {
      settings: { routes },
    },
  } = useApp()
  const redirect = !member && typeof window !== "undefined"

  if (redirect && ready) navigate(routes?.ONBOARDING, { replace: true })

  return !redirect && ready ? <Component {...props} /> : null
}

export const withoutMember = Component => ({ ...props }) => {
  const {
    ready,
    member,
    config: {
      settings: { routes },
    },
    redirectUrl,
  } = useApp()
  const redirect = member && typeof window !== "undefined"

  if (redirect && ready && !redirectUrl) navigate(routes?.PROFILE, { replace: true })

  return !redirect && ready ? <Component {...props} /> : null
}

export const withMembership = Component => ({ ...props }) => {
  const {
    ready,
    member,
    config: {
      settings: { routes },
    },
  } = useApp()

  const redirect = !member?.membership?.status && typeof window !== "undefined"

  if (redirect && ready) navigate(routes?.MEMBERSHIPS, { replace: true })

  return !redirect && ready ? <Component {...props} /> : null
}

export const withMembershipVIP = Component => ({ ...props }) => {
  const {
    ready,
    member,
    config: {
      settings: { routes },
    },
  } = useApp()

  const redirect = !member?.membership?.vip && typeof window !== "undefined"

  if (redirect && ready) navigate(routes?.MEMBERSHIPS, { replace: true })

  return !redirect && ready ? <Component {...props} /> : null
}

export const withoutMembership = Component => ({ ...props }) => {
  const {
    ready,
    member,
    config: {
      settings: { routes },
    },
  } = useApp()

  const redirect = member?.membership?.status && typeof window !== "undefined"

  if (redirect && ready) navigate(routes?.PROFILE, { replace: true })

  return !redirect && ready ? <Component {...props} /> : null
}
